import { addEventListener } from "@oddcamp/js-utils/src/event"

import init from "../../shared/utils/init"

init(() => {
  addEventListener(`.header__nav a.\\--disabled`, `click`, (e) =>
    e.preventDefault()
  )

  addEventListener(`.header__mobile button.\\--nav`, `click`, (e, target) => {
    e.preventDefault()
    const header = target.closest(`.header`)
    header.classList.remove(`header--buttons`)
    header.classList.toggle(`header--nav`)
  })

  addEventListener(
    `.header__mobile button.\\--buttons`,
    `click`,
    (e, target) => {
      e.preventDefault()
      const header = target.closest(`.header`)
      header.classList.remove(`header--nav`)
      header.classList.toggle(`header--buttons`)
    }
  )
})
