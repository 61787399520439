export default (init) => {
  if (typeof Turbolinks !== `undefined`) {
    document.addEventListener(`turbolinks:load`, init)
  } else {
    if (document.readyState === `loading`) {
      document.addEventListener(`DOMContentLoaded`, init)
    } else {
      init()
    }
  }
}
