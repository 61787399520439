// images

require.context(`../../images`, true)

// stylesheets

import "../../stylesheets/brochure/brochure.scss"

// external

// ...

// internal

import "../shared/base/polyfills"
import "../shared/base/rails"
import "../shared/base/turbolinks"
import "../shared/base/omasks"

import "../brochure/components/header"
import "../brochure/pages/index-page"
