import Swiper from "swiper"

import init from "../../shared/utils/init"

// highlights

init(() => {
  const selector = `.index-highlights .swiper-container`
  if (!document.querySelectorAll(selector)) return

  new Swiper(selector, {
    slidesPerView: `auto`,
    spaceBetween: 7,
    centeredSlides: true,
  })
})

// latest news

init(() => {
  const selector = `.index-cards .swiper-container`
  if (!document.querySelectorAll(selector)) return

  new Swiper(selector, {
    slidesPerView: `auto`,
    spaceBetween: 7,
    centeredSlides: true,
  })
})
